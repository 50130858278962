<template>
  <div class="app-container" style="margin-left:30px;">
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item label="日期">
       <el-date-picker
        v-model="timeValue"
        type="monthrange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始月份"
        end-placeholder="结束月份"
        @change="getTimeRange"
        :default-time="['00:00:00', '23:59:59']"
      ></el-date-picker>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" icon="el-icon-search" plain round @click="search">搜索</el-button>
      </el-form-item>
    </el-form>
    <div style="width:100%;height:300px;box-shadow: 1px 1px 10px #ccc;margin-top:20px;padding-top:10px;">
      <lineChart :name="name" :tableData="tableData" />
    </div>
    <!-- 表格 -->
    <div style="margin-top:20px;">
      <el-table
        :data="tableData"
        border
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        style="width:100%;box-shadow: 1px 1px 10px #ccc;"
        height="350"
        :row-class-name="tableRowClassName"
      >
        <el-table-column prop="index" label="序号"></el-table-column>
        <el-table-column prop="time" label="月份"></el-table-column>
        <el-table-column prop="wx" label="微信付费"></el-table-column>
        <el-table-column prop="ali" label="支付宝付费"></el-table-column>
        <el-table-column prop="total" label="总计"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import lineChart from "../../components/figure/lineChart.vue";
export default {
  components: {
    lineChart
  },
  data() {
    return {
      loading:true,
      name: "系统月报",
      timeValue: [], //选中的日期
      tableData: [], //表格数据
      startTime:'',
      endTime:''
    };
  },
  created() {
    // 默认搜索前三月时间
    let startTime= new Date(new Date().getFullYear() + '-01'+ '-01').getTime();
    let endTime =new Date().setHours(0,0,0,0);
    this.startTime=startTime;
    this.endTime=endTime;
    this.timeValue=[startTime, endTime];
    this.getShopDaily();
  },
  methods: {
    //获取系统月报
    getShopDaily(time) {
      let data = {
        timeStempStart: this.startTime,
        timeStempEnd:this.endTime
      };
      this.$axios
        .get("/reportForm/sysChargeByMonthLimits", { params: data })
        .then(res => {
          this.loading=false;
          if (res.data.state === 1) {
            let index = 0;
            res.data.charge.reverse().map(item => {
              item.index = ++index;
              item.wx = item.wx / 100;
              item.ali = item.ali / 100;
              item.yz = item.yz / 100;
              item.total = item.total / 100;
              return item;
            });
            this.tableData = res.data.charge;
          }
        });
    },
    //搜索
    search() {
      this.getShopDaily();
    },
    get3MonthBefor(){
      var resultDate,year,month,date,hms;
      var currDate = new Date();
      year = currDate.getFullYear();
      month = currDate.getMonth()+1;
      date = currDate.getDate();
      hms = currDate.getHours() + ':' + currDate.getMinutes() + ':' + (currDate.getSeconds() < 10 ? '0'+currDate.getSeconds() : currDate.getSeconds());
      switch(month)
      {
        case 1:
        case 2:
        case 3:
          month += 9;
          year--;
          break;
        default:
          month -= 3;
          break;
      }
      month = (month < 10) ? ('0' + month) : month;
      resultDate = year + '-'+month+'-'+date+' ' + hms;
      return resultDate;
    },
    getTimeRange (value) {
      console.log(value[1]);
      let date = new Date(value[1]); //value的格式为YYYY-MM-DD
      let month = (date.getMonth() + 1).toString().padStart(2,'0'); //获取月份，如果月份小于10加个‘0’
      let year = date.getFullYear();
      this.startTime = year + '' + month + '01';
      let day = new Date(year,month,0);
      this.startTime=new Date(value[0]).getTime();
      this.endTime=new Date(year + '-' + month + '-' + day.getDate()).getTime()+57599000;
    },
     // 表格颜色
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "success-rows";
      }
      return "";
    },
  }
};
</script>

<style>
.el-table td {
  text-align: center;
  padding: 10px 0;
}
.el-table th {
  padding: 5px 0;
  text-align: center;
}
</style>